import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AlertPopUp from "../../../Constants/AlertPopUp";
import {
  Autocomplete,
  Box,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import { red, yellow, green } from "@mui/material/colors";
import {
  checkRoutesM,
  checkRoutesMN,
  checkRoutesU,
} from "../../../Constants/routes";

const AccessControl = () => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let userRole = userData.userrole ? userData.userrole : "";
  const [allEmp, setAllEmp] = useState([]);

  // State to store selected employee
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [checkedState, setCheckedState] = useState([]);

  // For success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Handle checkbox change
  const handleCheckboxChange = (routeIndex, subRouteIndex = null) => {
    setCheckedState((prevState) => {
      const newState = [...prevState];

      if (subRouteIndex !== null) {
        // Toggling sub-route
        newState[routeIndex].subRoutes[subRouteIndex].checked =
          !newState[routeIndex].subRoutes[subRouteIndex].checked;

        // After toggling, check if all subRoutes are checked
        const areAllSubRoutesChecked = newState[routeIndex].subRoutes.every(
          (subRoute) => subRoute.checked
        );

        // Set the top-level route.checked based on subRoutes
        newState[routeIndex].checked = areAllSubRoutesChecked;
      } else {
        // Toggling main route
        const newCheckedState = !newState[routeIndex].checked;
        newState[routeIndex].checked = newCheckedState;

        // Update all subRoutes based on the main route's checked state
        newState[routeIndex].subRoutes = newState[routeIndex].subRoutes.map(
          (subRoute) => ({
            ...subRoute,
            checked: newCheckedState,
          })
        );
      }

      return newState;
    });
  };

  const handleSave = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/user/updateaccess",
        {
          companyemail,
          companyid,
          userid: selectedEmp.userid,
          access: checkedState,
        },
        { withCredentials: true }
      );
      console.log(res, "res");
      if (res.data && res.data.message == "Modules and sub-modules updated") {
        setCheckedState(checkedState);
        setAlertType("success");
        setOpen(true);
        setMsg("Changes Applied");
      }
    } catch (error) {
      setAlertType("error");
      setOpen(true);
      setMsg("Something went wrong");
    }
  };

  // Function to get all employees
  const getAllEmp = async () => {
    try {
      let res = await axios.post(
        "https://hrms.vliv.app/employee/get",
        { companyemail, companyid },
        { withCredentials: true }
      );
      if (res.data && Array.isArray(res.data)) {
        setAllEmp(
          res.data.map((ele) => {
            return {
              ...ele,
              label: `${ele.empid} ${ele.name}`,
            };
          })
        );
      } else {
        setAllEmp([]);
      }
    } catch (error) {
      console.log(error, "error");
      setAlertType("error");
      setOpen(true);
      setMsg("Error while getting employee");
    }
  };

  // Handle employee selection
  const handleEmpChange = async (event, value) => {
    if (value) {
      setSelectedEmp(value);
      try {
        let res = await axios.post(
          "https://hrms.vliv.app/user/getbyuserid",
          {
            userid: value.userid,
            companyid,
            companyemail,
          },
          { withCredentials: true }
        );
        console.log(res, "emp change res");
        if (
          res.data &&
          res.data.data &&
          res.data.data.access &&
          res.data.data.access.length > 0
        ) {
          setCheckedState(res.data.data.access);
        } else {
          if (value.role == "masteradmin" || value.role == "HR") {
            setCheckedState(checkRoutesM);
          } else if (value.role == "Manager") {
            setCheckedState(checkRoutesMN);
          } else {
            setCheckedState(checkRoutesU);
          }
        }
      } catch (error) {
        if (value.role == "masteradmin" || value.role == "HR") {
          setCheckedState(checkRoutesM);
        } else if (value.role == "Manager") {
          setCheckedState(checkRoutesMN);
        } else {
          setCheckedState(checkRoutesU);
        }
      }
    }
  };

  useEffect(() => {
    getAllEmp();
  }, []);

  return (
    <>
      <Box borderRadius={2} my={2}>
        <Grid container p={2} gap={0.5} mb={1}>
          <Grid item sm={12} md={4}>
            <Autocomplete
              size="small"
              disablePortal
              options={allEmp.filter((emp) => emp.userid)}
              sx={{ width: 300 }}
              onChange={handleEmpChange} // Handle selection change
              renderInput={(params) => (
                <TextField {...params} label="Select Employee" />
              )}
            />
          </Grid>
          {selectedEmp && (
            <Grid item sm={12} md={2}>
              <Typography variant="h6" className="colored-heading">
                Role: {selectedEmp.role}
              </Typography>
            </Grid>
          )}
          {selectedEmp && (
            <Grid
              item
              md={5.5}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    onChange={(e) => e.preventDefault()}
                    sx={{
                      color: green[800],
                      "&.Mui-checked": {
                        color: green[600],
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="h6" className="colored-heading">
                    Complete Access
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={false}
                    onChange={(e) => e.preventDefault()}
                    sx={{
                      color: yellow[900],
                      "&.Mui-checked": {
                        color: yellow[800],
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="h6" className="colored-heading">
                    Partial Access
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={false}
                    onChange={(e) => e.preventDefault()}
                    sx={{
                      color: red[900],
                      "&.Mui-checked": {
                        color: red[800],
                      },
                    }}
                  />
                }
                label={
                  <Typography variant="h6" className="colored-heading">
                    No Access
                  </Typography>
                }
              />
            </Grid>
          )}
        </Grid>
        {selectedEmp && (
          <>
            <Grid container alignItems="center" spacing={1} mb={0.2} px={2}>
              <Grid item xs={12} sm={4}>
                {" "}
                <Typography variant="h6">Modules</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">Sub Modules</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="column" p={2}>
              {checkedState.map((route, routeIndex) => (
                <Grid item key={route.path}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    className="newshadow"
                    mb={0.2}
                  >
                    {/* Left side: Top-level route */}
                    <Grid item xs={12} sm={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // disabled={
                            //   (selectedEmp &&
                            //     selectedEmp.role == "masteradmin") ||
                            //   (selectedEmp.role == "Manager" &&
                            //     (route.name == "Payroll" ||
                            //       route.name == "Report")) ||
                            //   (selectedEmp.role == "User" &&
                            //     [
                            //       "Home",
                            //       "Recruitments",
                            //       "Employee Management",
                            //       "Payroll",
                            //       "Performance",
                            //       "Report",
                            //     ].includes(route.name))
                            // }
                            checked={route.checked}
                            onChange={(e) => {
                              if (
                                (selectedEmp &&
                                  selectedEmp.role == "masteradmin") ||
                                (selectedEmp.role == "Manager" &&
                                  (route.name == "Payroll" ||
                                    route.name == "Report")) ||
                                (selectedEmp.role == "User" &&
                                  [
                                    "Home",
                                    "Recruitments",
                                    "Employee Management",
                                    "Payroll",
                                    "Performance",
                                    "Report",
                                  ].includes(route.name))
                              ) {
                                e.preventDefault();
                                return;
                              }
                              handleCheckboxChange(routeIndex);
                            }}
                            sx={
                              route.checked
                                ? {
                                    color: green[800],
                                    "&.Mui-checked": {
                                      color: green[600],
                                    },
                                  }
                                : route.subRoutes.some(
                                    (subroute) => subroute.checked
                                  )
                                ? {
                                    color: yellow[900],
                                    "&.Mui-checked": {
                                      color: yellow[800],
                                    },
                                  }
                                : {
                                    color: red[900],
                                    "&.Mui-checked": {
                                      color: red[800],
                                    },
                                  }
                            }
                          />
                        }
                        label={
                          <Typography variant="h6" className="colored-heading">
                            {route.name}
                          </Typography>
                        }
                      />
                    </Grid>

                    {/* Right side: Subroutes */}
                    <Grid item xs={12} sm={8}>
                      <Grid container spacing={1}>
                        {route.subRoutes &&
                          route.subRoutes.map((subRoute, subRouteIndex) => (
                            <Grid item key={subRoute.path} xs={6} sm={4}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={
                                      (selectedEmp &&
                                        selectedEmp.role == "masteradmin") ||
                                      (selectedEmp.role == "Manager" &&
                                        (route.name == "Payroll" ||
                                          route.name == "Report")) ||
                                      (selectedEmp.role == "User" &&
                                        [
                                          "Home",
                                          "Recruitments",
                                          "Employee Management",
                                          "Payroll",
                                          "Performance",
                                          "Report",
                                        ].includes(route.name))
                                    }
                                    checked={subRoute.checked}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        routeIndex,
                                        subRouteIndex
                                      )
                                    }
                                  />
                                }
                                label={<Typography>{subRoute.name}</Typography>}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Box my={1} textAlign={"right"} pr={2}>
              <Button className="saveBtn" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default AccessControl;

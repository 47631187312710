import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRecruitment,
  multiDeleteRecruitment,
  multiStatusChangeRecruitment,
} from "../../../../redux/recruitmentprocess/actions";
import Cookies from "js-cookie";
import { Alert, Box, IconButton } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../../../../Constants/dateFormat";
import Loader from "../../../../Constants/Loader";
import AlertPopUp from "../../../../Constants/AlertPopUp";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import { IoMdShare } from "react-icons/io";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import RowDetailsModal from "./RowDetailsModal";
import EditRecruitmentDrawer from "../Recruitment/EditRecruitmentDrawer";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { formpage, hrmsDomain } from "../../../../Constants/api";
import { styled } from "@mui/system";
import { Button } from "@mui/material";
import axios from "axios";
const RecruitmentProcess = () => {
  // COOKIES PART
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  let userRole = userData.userrole ? userData.userrole : "";
  // COOKIES PART

  const navigate = useNavigate();

  // REDUX PART
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state) => state.recruitmentReducer
  );
  // REDUX PART
  // console.log(data, "data")

  const [tabvalue, setTabValue] = React.useState("unapproved");

  //for view modal
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const [refresh, setRefresh] = useState(false);

  const handleRowClick = (params, event) => {
    if (event.target.closest(".MuiDataGrid-cell--withRenderer")) {
      return;
    }
    setSelectedRowId(params.row.id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRowId(null);
  };

  //for edit modal
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleEditClick = (id) => {
    setSelectedRowId(id);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedRowId(null);
  };

  //for multi delete
  const [selectedRows, setSelectedRows] = React.useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  //for copying link

  const handleCopyClick = (id) => {
    const linkToCopy = `${process.env.NODE_ENV == "production" ? hrmsDomain : formpage}/jobapplications?key=${companyid}&recid=${id}`;
    if (copy(linkToCopy)) {
      setAlertType("success");
      setOpen(true);
      setMsg("Link copied successfully");
    } else {
      setAlertType("error");
      setOpen(true);
      setMsg("Failed to copy");
    }
  };

  const handleCopyLink = async (id) => {
    const linkToCopy = `${process.env.NODE_ENV == "production" ? hrmsDomain : formpage}/jobapplications?key=${companyid}&recid=${id}`;

    try {
      await navigator.share({ url: linkToCopy });
      // notifySuccess("Link shared successfully!");
    } catch (error) {
      console.error("Error sharing link:", error);
      setAlertType("error");
      setOpen(true);
      setMsg("Failed to share link");
    }
  };

  //for success alert
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //for DATA GRID

  const columns = [
    { field: "rid", headerName: "RID", flex: 1 },
    { field: "createdAt", headerName: "Application Date", flex: 1 },
    { field: "raisedby", headerName: "Raised By", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    { field: "positions", headerName: "Position", flex: 2 },
    { field: "employeetype", headerName: "Type", flex: 1 },
    { field: "level", headerName: "Level", flex: 1 },
    { field: "experience", headerName: "Experience", flex: 1 },
    {
      field: "jd",
      headerName: "JD",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <IconButton onClick={() => handleJDClick(params.row)}>
            <AttachFileIcon />
          </IconButton>
        ) : null,
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
    // sahid changes start

    // Sahid Changes end
    {
      field: "action",
      headerName: "Actions",
      flex: 2,
      renderCell: (params) => (
        <Box>
          {tabvalue == "unapproved" && (
            <Tooltip title="Edit Recruitment" placement="top-end">
              <IconButton onClick={() => handleEditClick(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}

          {tabvalue == "approved" ? (
            <Tooltip title="Copy Link" placement="top-end">
              <IconButton>
                <ContentCopyIcon
                  onClick={() => handleCopyClick(params.row.id)}
                />
              </IconButton>
            </Tooltip>
          ) : null}
          {tabvalue == "approved" ? (
            <Tooltip title="Share Link" placement="top-end">
              <IconButton>
                <IoMdShare onClick={() => handleCopyLink(params.row.id)} />
              </IconButton>
            </Tooltip>
          ) : null}
          {tabvalue == "approved" ? null : (
            <IconButton
              onClick={() =>
                dispatch(
                  multiDeleteRecruitment(
                    [params.row.id],
                    setOpen,
                    setMsg,
                    setAlertType,
                    navigate
                  )
                )
              }
            >
              <DeleteOutlineIcon sx={{ color: "red" }} />
            </IconButton>
          )}
        </Box>
      ),
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },
  ];

  if (tabvalue == "approved") {
    columns.splice(9, 0, {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "custom-header",
      renderCell: (params) => {
        // console.log("Row Status:", params.row); // Debugging statement
        return (
          <select
            value={params.row.poststatus}
            onChange={(e) => handleStatusChange(params.row.id, e.target.value)}
            style={{
              width: "100%",
              padding: "4px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              color: params.row.poststatus === "Posted" ? "green" : "orange",
            }}
          >
            <option
              style={{ fontSize: "18px", color: "black" }}
              value="Not Posted"
            >
              Not posted
            </option>
            <option style={{ fontSize: "18px", color: "black" }} value="Posted">
              Posted
            </option>
          </select>
        );
      },
      cellClassName: "MuiDataGrid-cell--withRenderer",
    },)
  }
  console.log("columns", columns);
  // sahid code start
  const handleStatusChange = (id, value) => {
    console.log(id, value, "status onchane");
    axios
      .post(`/recruitment/updatepoststatus`, { id, value }, { withCredentials: true })
      .then((res) => {
        console.log(res.data, "post status update response");
        setRefresh(!refresh);
      })
      .catch((err) => console.log(err));
  };
  // sahid code end
  const rows =
    data.length > 0
      ? data
        .filter((recruitment) => recruitment.status == tabvalue)
        .map((recruit) => ({
          id: recruit.id,
          rid: `RID-${recruit.id}`,
          createdAt: formatDate(recruit.createdAt),
          raisedby: recruit.raisedby,
          department: recruit.department,
          positions: recruit.positions,
          employeetype: recruit.employeetype,
          level: recruit.level,
          experience: recruit.experience,
          jd: recruit.jd,
          poststatus: recruit.poststatus,
        }))
      : [];

  //for DATA GRID

  const handleJDClick = (row) => {
    const link = document.createElement("a");
    link.href = row.jd;
    link.download = row.jd.split("/").pop() || "JD_File";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    dispatch(getRecruitment({ companyid, companyemail }));
  }, [dispatch, refresh]);



  return (
    <>
      <Box className="custom-mui">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
              // aria-label="lab API tabs example"
              >
                <Tab
                  label="Un Approved"
                  value="unapproved"
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Approved"
                  value="approved"
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box>
            <TabPanel value="unapproved">
              <Box
                sx={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                {userRole == "masteradmin" && (
                  <div>
                    <Tooltip title="Mark as approve" placement="top-end">
                      <Button
                        variant="contained"
                        disabled={selectedRows.length === 0}
                        sx={{
                          backgroundColor: "#37668F",
                          color: "white",
                          "&:hover": {
                            backgroundColor:
                              selectedRows.length === 0 ? "#e0e0e0" : "#274d6a",
                            cursor:
                              selectedRows.length === 0
                                ? "not-allowed"
                                : "pointer",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "#e0e0e0",
                            color: "#9e9e9e",
                          },
                          margin: "8px 0px",
                          marginRight: "24px",
                        }}
                        startIcon={
                          <AssignmentTurnedInIcon
                            sx={{
                              color:
                                selectedRows.length === 0 ? "#9e9e9e" : "white",
                            }}
                          />
                        }
                        onClick={() =>
                          dispatch(
                            multiStatusChangeRecruitment(
                              selectedRows,
                              setOpen,
                              setMsg,
                              setAlertType,
                              navigate
                            )
                          )
                        }
                      >
                        Approve
                      </Button>
                    </Tooltip>
                  </div>
                )}
                {selectedRows.length > 1 && (
                  <div>
                    <Tooltip title="Delete All" placement="top-end">
                      <DeleteSweepIcon
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          dispatch(
                            multiDeleteRecruitment(
                              selectedRows,
                              setOpen,
                              setMsg,
                              setAlertType,
                              navigate
                            )
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                )}
              </Box>
              <Box sx={{ height: "auto", width: "100%" }}>
                <DataGrid
                  rows={loading ? [] : rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15, 20, rows.length]}
                  autoHeight
                  components={{
                    LoadingOverlay: <Loader />,
                  }}
                  loading={loading}
                  onRowClick={handleRowClick}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newSelection) => {
                    handleSelectionModelChange(newSelection);
                  }}
                />
              </Box>
            </TabPanel>
            <TabPanel value="approved">
              <Box
                sx={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {selectedRows.length > 1 && (
                  <div>
                    <Tooltip title="Delete All" placement="top-end">
                      <DeleteSweepIcon
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          dispatch(
                            multiDeleteRecruitment(
                              selectedRows,
                              setOpen,
                              setMsg,
                              setAlertType,
                              navigate
                            )
                          )
                        }
                      />
                    </Tooltip>
                  </div>
                )}
              </Box>
              <Box sx={{ height: "auto", width: "100%", px: 1 }}>
                <DataGrid
                  rows={loading ? [] : rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 15, 20, rows.length]}
                  autoHeight
                  components={{
                    LoadingOverlay: <Loader />,
                  }}
                  loading={loading}
                  onRowClick={handleRowClick}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newSelection) => {
                    handleSelectionModelChange(newSelection);
                  }}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        {modalOpen ? (
          <RowDetailsModal
            open={modalOpen}
            handleClose={handleCloseModal}
            rowId={selectedRowId}
          />
        ) : null}
        {drawerOpen ? (
          <EditRecruitmentDrawer
            drawerOpen={drawerOpen}
            rowId={selectedRowId}
            handleCloseDrawer={handleCloseDrawer}
          />
        ) : null}
      </Box>
      {open && (
        <AlertPopUp
          open={open}
          msg={msg}
          handleClose={handleClose}
          type={alertType}
        />
      )}
    </>
  );
};

export default RecruitmentProcess;

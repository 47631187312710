import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { employeeExitGetbyID } from "../../../../../Constants/api";
import axios from "axios";
import { formatDate } from "../../../../../Constants/dateFormat";
import { calculateShortfallArray } from "../../../../../Constants/constants";
import AlertPopUp from "../../../../../Constants/AlertPopUp";
import { updateEmpExit } from "../../../../../redux/actions/actions.hrms/employeeExit.actions";

const EditFF = () => {
  const cookieData = Cookies.get("userinfo");
  let userData = cookieData ? JSON.parse(cookieData) : {};
  let companyid = userData.companyid ? userData.companyid : "";
  let companyemail = userData.companyemail ? userData.companyemail : "";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [data, setData] = useState({ payrollarray: [], encasharray: [] });
  const [shortFallArray, setShortFallArray] = useState([]);
  const [ffData, setFfData] = useState({});

  //for success alert
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async() => {
    try {
      let res= await axios.post("https://hrms.vliv.app/payroll/updatefabdf",{empid:data.empid,companyemail,companyid,lwddate:data.servenoticeperiod=="Yes"?data.lwddate:data.expectlwddate,fandfamount:ffData.totalencashment - ffData.shortfallcash},{withCredentials:true});
      console.log(res,"res fnf payroll");
    } catch (error) {
      console.log(error,"error fnf payroll");
    }
    dispatch(updateEmpExit({...data,totalleaveencashment:ffData.totalencashment,totalshortfallpayout:ffData.shortfallcash,totalsettlementamount: ffData.totalencashment - ffData.shortfallcash }, setOpen, setMsg, setAlertType, navigate));
  };

  useEffect(() => {
    if (id) {
      axios
        .post(employeeExitGetbyID, { id },{withCredentials:true})
        .then((res) => {
          setData((prev) => ({
            ...prev,
            ...res.data?.[0],
          }));
          const {
            resignationdate,
            expectlwddate,
            servenoticeperiod,
            lwdperpolicy,
            shortfalldays,
          } = res.data[0] || {};

          setShortFallArray(
            calculateShortfallArray(
              resignationdate,
              expectlwddate,
              servenoticeperiod,
              lwdperpolicy,
              shortfalldays
            )
          );

          if (res.data[0].empid) {
            axios
              .post("https://hrms.vliv.app/exit/getfandf", {
                companyemail,
                companyid,
                empid: res.data[0].empid,
              },{withCredentials:true})
              .then((response) => {
                if (response.data) {
                  setFfData(response.data);
                } else {
                  setFfData({});
                }
              })
              .catch((error) => {
                console.log(error, "error fnf calculation");
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  // console.log(ffData, "ff data");

  return (
    <>
      <Box>
        <Box p={1} m={1} px={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography
            variant="h5"
            color="#0B4A6F"
            fontWeight={"bold"}
            display={"flex"}
            alignItems={"center"}
            gap={2}
          >
            <IconButton
              onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
            >
              <WestIcon sx={{ color: "#0B4A6F" }} />
            </IconButton>
            <span>Full and Final</span>
          </Typography>
        </Box>
        <Box p={1} m={1} px={3} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Employee Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Name</Typography>
              <Typography className="view-page-title">{data.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Employee ID</Typography>
              <Typography className="view-page-title">
                {data.empid || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Department</Typography>
              <Typography className="view-page-title">
                {data.department || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Position</Typography>
              <Typography className="view-page-title">
                {data.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Reporting To</Typography>
              <Typography className="view-page-title">
                {data.reportmanager || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Date of Joining
              </Typography>
              <Typography className="view-page-title">
                {data.joiningdate ? formatDate(data.joiningdate) : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={1} m={1} px={3} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom mb={1.5}>
            Work Flow
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Exit Type</Typography>
              <Typography className="view-page-title">
                {data.exittype || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">Applied Date</Typography>
              <Typography className="view-page-title">
                {data.applieddate ? formatDate(data.applieddate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Resignation Date
              </Typography>
              <Typography className="view-page-title">
                {data.resignationdate ? formatDate(data.resignationdate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">LWD Date</Typography>
              <Typography className="view-page-title">
                {data.lwddate ? formatDate(data.lwddate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Expected LWD Date
              </Typography>
              <Typography className="view-page-title">
                {data.expectlwddate ? formatDate(data.expectlwddate) : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                LWD As per policy
              </Typography>
              <Typography className="view-page-title">
                {data.lwdperpolicy || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Shortfall Days
              </Typography>
              <Typography className="view-page-title">
                {data.shortfalldays || "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                No. of days served
              </Typography>
              <Typography className="view-page-title">
                {(+data.lwdperpolicy || 0) - (+data.shortfalldays || 0)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Leaving Reason
              </Typography>
              <Typography className="view-page-title">
                {data.leavingreason || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box p={1} m={1} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Grid container>
            <Grid item xs={12} md={6} style={{ height: "auto" }}>
              <Box
                p={1}
                pb={3}
                bgcolor={"#F2F4F7"}
                borderRadius={3}
                height="100%"
                display="flex"
                flexDirection="column"
              >
                <Typography variant="h6" gutterBottom mb={1.5}>
                  Leave Encashment
                </Typography>

                <Box overflow="auto" flexGrow={1}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead className="datagrid-header">
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Leave Type
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Balance
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Encash
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ffData && ffData.data && ffData.data.length > 0 ? (
                          <>
                            {ffData.data.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.leavetype}</TableCell>
                                <TableCell>{row.remaining || 0} Days</TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    className="formInputs"
                                    value={row.encashment || 0}
                                    disabled
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            {/* Total Row */}
                            <TableRow>
                              <TableCell sx={{ fontSize: "17px" }}>
                                Total Leave Encashment
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell>
                                <TextField
                                  fullWidth
                                  className="formInputs"
                                  value={ffData.totalencashment || 0}
                                  disabled
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              No Leave available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} style={{ height: "auto" }}>
              <Box
                p={1}
                pb={3}
                bgcolor={"#F2F4F7"}
                borderRadius={3}
                height="100%"
                display="flex"
                flexDirection="column"
              >
                <Typography variant="h6" gutterBottom mb={1.5}>
                  Shortfall Days
                </Typography>

                <Box overflow="auto" flexGrow={1}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead className="datagrid-header">
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Payroll Month
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }}>
                            Shortfall in Notice
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {shortFallArray.length > 0 ? (
                          shortFallArray.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {row.month} {row.year}
                              </TableCell>
                              <TableCell>
                                {row.shortfallinnotice} Days
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2}>
                              No Shortfall available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box p={1} m={1} pb={3} bgcolor={"#F2F4F7"} borderRadius={3}>
          <Typography variant="h6" gutterBottom>
            Settlement Info
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Resignation Date
              </Typography>
              <TextField
                size="small"
                fullWidth
                type="date"
                value={data.resignationdate}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">LWD Date</Typography>
              <TextField
                size="small"
                fullWidth
                type="date"
                value={data.lwddate}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Expect LWD Date
              </Typography>
              <TextField
                size="small"
                fullWidth
                type="date"
                value={data.expectlwddate}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className="view-page-value">
                Settlement Date
              </Typography>
              <TextField
                size="small"
                fullWidth
                type="date"
                value={data.settlementdate}
                name="settlementdate"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className="view-page-value">Remarks</Typography>
              <TextField
                size="small"
                fullWidth
                multiline
                rows={3}
                value={data.remarks}
                onChange={handleChange}
                name="remarks"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className="view-page-value">
                Total Leave Encashment
              </Typography>
              <TextField
                size="small"
                fullWidth
                value={ffData && ffData.totalencashment}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className="view-page-value">
                Total Shortfall Payout
              </Typography>
              <TextField
                size="small"
                fullWidth
                value={ffData && ffData.shortfallcash}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className="view-page-value">
                Total Settlement Amount
              </Typography>
              <TextField
                size="small"
                fullWidth
                value={
                  (ffData.totalencashment || 0) - (ffData.shortfallcash || 0)
                }
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        mt="auto"
        boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        bgcolor={"background.paper"}
        p={2}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button
          className="saveBtn"
          variant="contained"
          onClick={handleUpdate}
          disabled={data.fandfstatus=="Settled" || data.status=="Rejected"}
        >
          Save
        </Button>
        <Button
          className="cancelBtn"
          variant="outlined"
          sx={{ ml: 2 }}
          onClick={() => navigate(`/hrm/employeemanagement/employeeexit`)}
        >
          Cancel
        </Button>
      </Box>
      <AlertPopUp
        open={open}
        msg={msg}
        handleClose={handleClose}
        type={alertType}
      />
    </>
  );
};

export default EditFF;

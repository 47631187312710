import TabContext from "@mui/lab/TabContext";

import TabPanel from "@mui/lab/TabPanel";
import { Box, } from "@mui/material";
import React, { useState } from "react";

import Welcome from "./Welcome/Welcome";



const Summary = () => {
  const [tabvalue, setTabValue] = useState("welcome");
  return (
    <>
      <Box>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabvalue}>
            <TabPanel value="welcome" sx={{padding:1}} >
              <Box sx={{ height: "auto", width: "100%" }}>
               <Welcome/>
              </Box>
              </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default Summary;
